export default class UIElementState
{
  constructor()
  {

  }

  update(ui_element, normalized_mouse_position)
  {

  }

  on_enter(ui_element)
  {}

  on_exit(ui_element)
  {}
}
