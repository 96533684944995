export default class ViewState
{
  constructor(name)
  {
    this.name = name;
  }

  start()
  {
  }

  show()
  {
  }

  hide()
  {
  }

  on_enter()
  {
  }

  update()
  {
  }

  update_transition(global_view_data, transition_progress)
  {
  }

  on_exit()
  {
  }
}
